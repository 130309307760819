<template>
  <div class="overlay">
    <button class="close-btn" @click="close">X</button>
    <div class="image-container">
      <img v-for="(image, index) in validImages" :key="index" :src="image.url" class="image" />
      <img v-for="(image, index) in validImages" :key="index" :src="image.url" class="image" />
      <img v-for="(image, index) in validImages" :key="index" :src="image.url" class="image" />
    </div>
  </div>
</template>

<script>
import {computed } from 'vue';

export default {
  emits:['close'],
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const validImages = computed(() => {
      return props.images.filter(image => image.url && image.url.trim() !== '');
    });

    const close = () => {
      emit('close');
    };

    return { validImages, close };
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--danger);
  color: #fff;
  user-select: none;
  border-radius: 50%;
}

.image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  max-height: 80vh;
  margin: 1rem;
}
</style>